import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";

export default function TermsAccordion(props: any) {
    return (
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
        <Typography>Yieldmo Publisher Terms for Google OB</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography display="inline">

<h3>1. Service Description</h3>

Yieldmo offers a proprietary advertising marketplace, ad formats, data, technology, processes and other services (the <b>“Yieldmo Service”</b>), designed to facilitate the sale of advertising inventory (<b>“Ad Inventory”</b>) for owners or operators (each, a <b>“Publisher”</b>) of online websites, applications and/or other forms of media (<b>“Media”</b>). The Publisher entering into this Agreement may seek to enable the Yieldmo Service to provide bids from Yieldmo, or from Yieldmo’s integrated demand sources (<b>“Buyers”</b>), for Ad Inventory that Publisher offers for sale through Google’s Open Bidding program (the <b>“Google OB Service”</b>). Whether or not Publisher is permitted to access the Yieldmo Service in this manner (i) is within Yieldmo’s sole discretion, and (ii) requires, at a minimum, that the Media being made available by Publisher meets Yieldmo’s pre-approval standards.

<h3>2. Fees</h3>

Publisher agrees and acknowledges that Yieldmo will not pay Publisher directly for bids Yieldmo or its Buyers win under the Google OB Service; instead, Yieldmo will pay Google for bids Yieldmo wins as calculated in accordance with the agreement between Yieldmo and Google. Payments by Google to Publisher for such Ad Inventory will be subject to the relevant agreement between Google and Publisher. All amounts payable hereunder are exclusive of any sales, use, and other taxes (<b>“Taxes”</b>). Publisher is solely responsible for payment of any Taxes applicable to transactions under the Agreement or as a result of the provision or use of the Google OB Service, except for Taxes based on Yieldmo’s net income.

<h3>3. Operation of the Service; Privacy</h3>

<b>A. Ads.</b> Publisher hereby consents to the placement of advertisements (<b>“Ads”</b>) on the Ad Inventory made available by Publisher through the Google OB Service. THE YIELDMO SERVICE IS PROVIDED “AS-IS”, WITHOUT ANY WARRANTY, WHETHER EXPRESS OR IMPLIED.<br/>
<br/>
<b>B. Media.</b> Publisher is required to obtain Yieldmo’s prior written approval for all Media on which Publisher wishes to make Ad Inventory available through the Google OB Service. Yieldmo may restrict or remove any Media from the Google OB Service in its sole discretion.<br/>
<br/>
<b>C. Privacy.</b> Publisher will comply with all applicable laws and regulations governing information and data relating to individuals, including without limitation, if to the extent applicable: (1) EC Regulation 2016/679 (<b>"GDPR"</b>) on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and the EU e-Privacy Directive (Directive 2002/58/EC) (the <b>“e-Privacy Directive”</b>); (2) any applicable local laws or regulations; (3) the California Consumer Privacy Act of 2018 (<b>"CCPA"</b>); and (4) the self-regulatory principles and codes of conduct of the Network Advertising Initiative (<b>“NAI”</b>), the Digital Advertising Alliance (<b>“DAA”</b>) and the European Interactive Digital Advertising Alliance (<b>“EDAA”</b>), each of the foregoing as amended, replaced or supplemented from time to time (collectively, the <b>“Rules”</b>). Publisher will not use or disclose information regarding an end user’s interaction with an Ad delivered through the Google OB Service, unless such information is aggregated and anonymized prior to its use or disclosure so as not to identify any individual Buyer or advertiser. Publisher represents and warrants that it has obtained any legally required consent to the collection, use and disclosure of the data it makes available to Yieldmo sufficient to allow Yieldmo to process such data in connection with the Yieldmo Service, and that Publisher maintains a publicly-accessible privacy policy on its website that is in compliance with all Rules.<br/>
<br/>
<small>Last updated: March 8, 2021</small>
        </Typography>
        </AccordionDetails>
        </Accordion>  
    )
}

