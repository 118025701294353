import axios, { AxiosResponse } from "axios";
import { SubmitRequest } from "../models/SubmitRequest";

const BASE_PATH = '/app-portal/cta';

export function submit(request: SubmitRequest, siteListFile: File, monthlyPageViewsFile: File): Promise<AxiosResponse> {
  let formData = new FormData();
  formData.append('data', JSON.stringify(request));
  if (siteListFile) {
    formData.append('siteListFile', siteListFile, siteListFile.name);
  }
  if (monthlyPageViewsFile) {
    formData.append('monthlyPageViewsFile', monthlyPageViewsFile, monthlyPageViewsFile.name);  
  }
  return axios.post(`${BASE_PATH}/submit`, formData);  
}

export function options(): Promise<AxiosResponse<Options>> {
  return axios.get<Options>(`${BASE_PATH}/options`);  
}

export interface Options {
  verticals: Array<Vertical>;
}

export interface Vertical {
  id: number;
  name: string;
}