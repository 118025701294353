import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import logo from './logo.png';
import * as api from './api/Api';
import {Options} from './api/Api';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TermsAccordion from './components/TermsAccordion';
import FaqAccordion from './components/FaqAccordion';
import {SourceType} from './models/SourceType';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(0.5),
        width: '100%',
      },
    },
    control: {
      padding: theme.spacing(2),
      textAlign: 'center',
      margin: 'auto',
      color: theme.palette.text.secondary,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    terms: {
      marginTop: '20px',
      marginBottom: '20px'
    },
    tooltip:{
      fontSize: '14px',
  }})
);

enum MessageType {
  DATA_POINTS_REQUEST = 'DATA_POINTS_REQUEST',
  DATA_POINTS = 'DATA_POINTS',
  RESULT = 'RESULT',
  ACK = 'ACK',
  TEST = 'TEST'
}

enum AckType {
  DATA_POINTS = 'DATA_POINTS' ,
  RESULT = 'RESULT' ,
  TEST_NEXT = 'TEST_NEXT'
}

enum ResultType {
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
  OTHER = 'OTHER',
  ERROR = 'ERROR'
}

enum ActionState {
  INITIAL = 'INITIAL',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR',
}

enum TestDirective {
  START = 'START',
  NEXT = 'NEXT'
}

interface TimeZone {
  [key: string]: string;
}

const timezones: TimeZone = {
  'UTC-4': 'Eastern Daylight Time (New York)',
  'UTC-5': 'Central Daylight Time	(Chicago)',
  'UTC-6': 'Mountain Daylight Time (Salt Lake City)',
  'UTC-7': 'Pacific Daylight Time	(Los Angeles)',
  'UTC-8': 'Alaska Daylight Time (Anchorage)',
  'UTC-9': 'Hawaii-Aleutian Daylight Time	(Adak)',
  'UTC-10':	'Hawaii Standard Time (Honolulu)',
  'OTHER': 'Other'
}

const allowedOrigins = [
  'https://admanager.google.com',
  'https://apps.admob.com',
  'https://admanager-ics.corp.google.com',
  'https://admanager-qalive.qa.adz.google.com',
  'https://ics.corp.google.com',
];

const version = 'v2';

export default function App(props: any) {

  const[currentState, setCurrentState] = useState(ActionState.INITIAL);
  const token = useRef('');
  const publisherId = useRef('');
  const product = useRef('');
  const networkCode = useRef('');
  const redirectUrl = useRef('');
  const testMode = useRef(false);
  const testOrigin = useRef('');
  const resultReceived = useRef(false);
  const[dataPointsReceived, setDataPointsReceived] = useState(false);
  const[source, setSource] = useState(SourceType.YIELDMO);
  const[options, setOptions] = useState<Options>({
    verticals: []
  });

  const generateToken = () => {
    return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
  }

  const hasReferrerOrigin = (origin: string) => {
    return getReferrerOrigin() === origin;
  }

  const getReferrerOrigin = () => {
    return (new URL(document.referrer)).origin;
  }

  const isOriginValid = (origin: string) => {
    if (process.env.NODE_ENV === 'production' && !testMode.current) {
      return hasReferrerOrigin(origin)
        && allowedOrigins.includes(origin);
    } else {
      return true;
    }
  }

  const isTokenValid = (value: string) => {
    return token.current === value;
  }

  const sendMessage = (message: any) => {
    const referrerOrigin = getReferrerOrigin();
    const data = {version, "token": token.current, ...message}
    window.opener.postMessage(data, referrerOrigin);
    console.log('Sent window message to %s', referrerOrigin);
  }

  const onMessageReceived = (event: MessageEvent) => {
    console.log('Received window message from %s', event.origin);
    const json = event.data;

    const type = json['type'];
    if (type === MessageType.TEST) {
      handleTestResponse(json);
    } else if (isOriginValid(event.origin) && isTokenValid(json['token'])) {
      if (type === MessageType.DATA_POINTS) {
        handleDataPoints(json);
      } else if (type === MessageType.ACK) {
        handleAckResponse(json);
      } else {
        console.warn('Unexpected response', json);
      }
    } else {
      console.log(`Response is not permitted (token=${json['token']}, origin=${event.origin})`);
    }
  }

  const onWindowClosed = (event: BeforeUnloadEvent) => {
    if (!resultReceived.current) {
      console.log('Attempt to close window');
      sendResult(ResultType.CANCEL, false);
    }
  }

  const checkResultAck = () => {
    if (!resultReceived.current) {      
      window.location.replace(redirectUrl.current);
    }
  }

  const sendResult = (result: ResultType, keepWindowOpen: boolean) => {
    sendMessage({
      "type": MessageType.RESULT,
      "origin-product": product.current,
      "publisher-id": publisherId.current,
      "result-value": result,
      "keep-window-open": keepWindowOpen
    });
    setTimeout(() => {
      checkResultAck();
    }, 500);
    const referrerOrigin = getReferrerOrigin();
    console.log('Result sent to %s', referrerOrigin);
  }

  const sendAcknowledge = (ackType: AckType) => {
    sendMessage({
      "type": MessageType.ACK,
      "ack-type": ackType,
      "origin-product": product.current,
      "publisher-id": publisherId.current,
    });
  }

  // 1. Request for data points
  const requestDataPoints = () => {
    sendMessage({
      type: MessageType.DATA_POINTS_REQUEST
    });
  }

  // 2. Data points provided
  const handleDataPoints = (json: any) => {
    product.current = json['origin-product'];
    publisherId.current = (json['publisher-id']);
    networkCode.current = json['network-code'];
    redirectUrl.current = json['redirect-url'];
    setSource(product.current as SourceType);
    // 3. Message acknowledgment
    sendAcknowledge(AckType.DATA_POINTS);
    setDataPointsReceived(true);
  }

  // Handle: ACK
  const handleAckResponse = (json: any) => {
    // 5. Message acknowledgment
    if (json['ack-type'] === AckType.RESULT) {
      resultReceived.current = true
    }
  }

  // Handle: TEST
  const handleTestResponse = (json: any) => {
    console.log(json);
    const directive = json['test-directive'];
    if (directive === TestDirective.START) {
      testMode.current = true;
      testOrigin.current = json['test-origin'];
      console.log('Test Mode enabled');
      requestDataPoints();
    } else if (directive === TestDirective.NEXT) {
      console.log('Current State: ', currentState);
      if (currentState === ActionState.INITIAL) {
        setCurrentState(ActionState.SUBMITTING);
        sendAcknowledge(AckType.TEST_NEXT);
      } else if (currentState === ActionState.SUBMITTING) {
        setCurrentState(ActionState.SUBMITTED);
        sendAcknowledge(AckType.TEST_NEXT);
      } else if (currentState === ActionState.SUBMITTED) {
        sendResult(ResultType.SUCCESS, true);
      }
    }
  }

  const loadOptions = () => {
    api.options()
      .then((res) => {
        setOptions(res.data);
      });
  }

  useEffect(() => {
    console.log('CTA', version);
    loadOptions();
    if (window.opener && document.referrer) {
      window.addEventListener("beforeunload", onWindowClosed);
      token.current = generateToken();
      requestDataPoints();
    } else {
      console.log('Google integration disabled');
      return;
    }
  }, [])

  useEffect(() => {
    if (window.opener && document.referrer) {
      window.addEventListener('message', onMessageReceived, false);
    }
  }, [currentState, resultReceived]);

  useEffect(() => {
    if (dataPointsReceived) {
      formik.setFieldValue('publisherId', publisherId.current);
      formik.setFieldValue('product', product.current);
    }
  }, [dataPointsReceived]);

  interface InitialValues {
    publisherName?: string;
    publisherId?: string;
    product?: string;
    ownYourInventory?: string | null;
    verticals?: Number[];
    contactName?: string;
    contactEmail?: string;
    contactPhone?: string;
    address?: string;
    website?: string;
    developerWebsite?: string;
    timezone?: string;
    siteListType?: string;
    siteListText?: string;
    siteListFile: File;
    monthlyPageViewsType?: string;
    monthlyPageViewsText?: string;
    monthlyPageViewsFile: File;
    apacPercentage?: number | null;
    emeaPercentage?: number | null;
    latamPercentage?: number | null;
    usPercentage?: number | null;
    acceptedTerms: boolean;
  }

  const formik = useFormik<InitialValues>({
    initialValues: {
      publisherName: '',
      publisherId: '',
      product: '',
      ownYourInventory: null,
      verticals: [],
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      website: '',
      developerWebsite: '',
      timezone: '',
      siteListType: 'manually',
      siteListText: '',
      siteListFile: null as any,
      monthlyPageViewsType: 'manually',
      monthlyPageViewsText: '',
      monthlyPageViewsFile: null as any,
      apacPercentage: null,
      emeaPercentage: null,
      latamPercentage: null,
      usPercentage: null,
      acceptedTerms: false
    },
    validationSchema: Yup.object({
      siteListText: Yup.string()
        .max(4000, 'No more than 4,000 characters are allowed for manual site list input - please use the upload feature.'),
      monthlyPageViewsText: Yup.string()
        .max(500, 'No more than 500 characters are allowed for montly page views input - please use the upload feature.'),
      siteListFile: Yup.mixed().when("siteListType", {
        is: (value: string) => value === 'upload', // CHECK IF YOU NEED TO VALIDATE THE CURRENT FIELD
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed() // No validation
      }),
      monthlyPageViewsFile: Yup.mixed().when("monthlyPageViewsType", {
        is: (value: string) => value === 'upload', // CHECK IF YOU NEED TO VALIDATE THE CURRENT FIELD
        then: Yup.mixed().required('Required'),
        otherwise: Yup.mixed() // No validation
      })    

    }),
    onSubmit: values => {
      setCurrentState(ActionState.SUBMITTING);
      api.submit({
        ...values,
        source,
        ownYourInventory: values.ownYourInventory === 'yes',
      }, values.siteListFile, values.monthlyPageViewsFile)
      .then(() => {
        console.log('Submitted');
        if (dataPointsReceived) {
          // 4. The result is sent back (success)
          sendResult(ResultType.SUCCESS, true);
        }
        setCurrentState(ActionState.SUBMITTED);
      })
      .catch((e) => {
        // 4. The result is sent back (error)
        if (dataPointsReceived) {
          sendResult(ResultType.ERROR, true);
        }
        alert(e);
        setCurrentState(ActionState.ERROR);
      })
    }
  });

  const classes = useStyles();

  if (currentState === ActionState.SUBMITTED) {
    return (
      <Container maxWidth="md">
        <form onSubmit={formik.handleSubmit} className={classes.root}>
          <Grid container spacing={3} justify="center">
            <Grid>
              <img src={logo} width="auto" height="200px" alt="logo"/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">Your request has been submitted, thank you!</Typography>
            </Grid>
            <Grid>
              <Button href="https://yieldmo.com">Back to home page</Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    )
  }

  return (
    <Container maxWidth="md">
        <Backdrop open={currentState === ActionState.SUBMITTING} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form onSubmit={formik.handleSubmit} className={classes.root}>
          <Grid container spacing={3} justify="center">
            <Grid>
              <img src={logo} width="auto" height="200px" alt="logo"/>
            </Grid>
            <Grid xs={12}>

              <Typography variant="body1" style={{marginLeft: '10px'}}>
                Thank you for your interest in working with Yieldmo to increase revenue through your Google Open Bidding integration. Yieldmo delivers differentiated demand powered by proprietary high-impact and standard <a href="https://formats.yieldmo.com">ad formats</a>. To begin, complete the following application. For additional information about our program, refer to our FAQ or contact <a href="mailto:obsupport@yieldmo.com">OB Support</a>.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: '20px', marginBottom: '20px'}}>
              <FaqAccordion/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="publisherName"
                name="publisherName"
                label="Publisher Name"
                variant="outlined"
                value={formik.values.publisherName}
                onChange={formik.handleChange}
                required
                error={formik.touched.publisherName && Boolean(formik.errors.publisherName)}
                helperText={formik.touched.publisherName && formik.errors.publisherName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="publisherId"
                name="publisherId"
                label="Google ID (Publisher ID AdMob or Network ID Google Ad Manager)"
                variant="outlined"
                value={formik.values.publisherId}
                onChange={formik.handleChange}
                disabled={dataPointsReceived}
                required
                error={formik.touched.publisherId && Boolean(formik.errors.publisherId)}
                helperText={formik.touched.publisherId && formik.errors.publisherId}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="productLabel">Which Google Open Bidding product do you use?</InputLabel>
                <Select
                  id="product"
                  name="product"
                  labelId="productLabel"
                  label="Which Google Open Bidding product do you use? *"
                  value={formik.values.product}
                  onChange={formik.handleChange}
                  disabled={dataPointsReceived}
                  error={formik.touched.product && Boolean(formik.errors.product)}
                >
                  <MenuItem value="AD_MANAGER">Ad Manager</MenuItem>
                  <MenuItem value="ADMOB">AdMob</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="ownYourInventoryLabel">Do you own and operate your inventory?</InputLabel>
                <Select
                  id="ownYourInventory"
                  name="ownYourInventory"
                  labelId="ownYourInventoryLabel"
                  label="Do you own and operate your inventory? *"
                  value={formik.values.ownYourInventory || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.ownYourInventory && Boolean(formik.errors.ownYourInventory)}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="contactName"
                name="contactName"
                label="Contact Name"
                variant="outlined"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                required
                error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                helperText={formik.touched.contactName && formik.errors.contactName}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="contactEmail"
                name="contactEmail"
                label="Contact Email"
                variant="outlined"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                required
                error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                helperText={formik.touched.contactEmail && formik.errors.contactEmail}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="contactPhone"
                name="contactPhone"
                label="Contact Phone"
                variant="outlined"
                value={formik.values.contactPhone}
                onChange={formik.handleChange}
                required
                error={formik.touched.contactPhone && Boolean(formik.errors.contactPhone)}
                helperText={formik.touched.contactPhone && formik.errors.contactPhone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                name="address"
                label="Address"
                variant="outlined"
                value={formik.values.address}
                onChange={formik.handleChange}
                required
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="website"
                name="website"
                label="Website"
                variant="outlined"
                value={formik.values.website}
                onChange={formik.handleChange}
                required
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="developerWebsite"
                name="developerWebsite"
                label="Developer Website"
                variant="outlined"
                value={formik.values.developerWebsite}
                onChange={formik.handleChange}
                error={formik.touched.developerWebsite && Boolean(formik.errors.developerWebsite)}
                helperText={formik.touched.developerWebsite && formik.errors.developerWebsite}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="timezoneLabel">Time Zone</InputLabel>
                <Select
                  id="timezone"
                  name="timezone"
                  labelId="timezoneLabel"
                  label="Time Zone"
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                  error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                >
                {Object.keys(timezones).map(key => {
                  return (
                    <MenuItem value={key}>{timezones[key]}</MenuItem>
                  )
                })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Inventory</Typography>
            </Grid>
            <Grid item xs={6}>
     <FormControl component="fieldset">
                <FormLabel component="legend">Please provide your montly page view information</FormLabel>
                <RadioGroup aria-label="monthlyPageViews" name="monthlyPageViewsType"
                value={formik.values.monthlyPageViewsType} onChange={formik.handleChange}>
                  <FormControlLabel value="manually" control={<Radio />} label="I will enter my page-view figures manually" />
                  <FormControlLabel value="upload" control={<Radio />} label="I want to  upload a screenshot of my pageview data" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {formik.values.monthlyPageViewsType === 'upload' &&          
             <Grid item xs={6}>      
             <FormControl fullWidth>                                            
               <Typography variant="caption">Monthly Page Views Screenshot *</Typography>
               <Box className={classes.flexRow}>
                 <Tooltip
                     title={<span className={classes.tooltip}>Upload a screen shot of monthly page views from Google Reporting</span>}
                     placement="bottom" arrow
                 >
               <TextField        
                 required          
                 size="medium"
                 value={(formik.values.monthlyPageViewsFile &&  formik.values.monthlyPageViewsFile.name) || ''}   
                 error={formik.touched.monthlyPageViewsFile && Boolean(formik.errors.monthlyPageViewsFile)}
                 helperText={formik.touched.monthlyPageViewsFile && formik.errors.monthlyPageViewsFile}                                      
                 inputProps={
                   { readOnly: true, }
                 }/>
                 </Tooltip>
               <label htmlFor="monthlyPageViewsFile">
                 <Button variant="contained" component="span">
                 Browse
                 </Button>
               </label>
               <input
                 id="monthlyPageViewsFile"
                 type="file"
                 hidden                
                 onChange={(e) => e.target.files
                   && formik.setFieldValue('monthlyPageViewsFile', e.target.files[0])}
               />                
               </Box>
             </FormControl>              
           </Grid>
            }
            {formik.values.monthlyPageViewsType === 'manually' &&
            <Grid item xs={6}>
                <TextField
                id="monthlyPageViewsText"
                name="monthlyPageViewsText"
                label="Monthly Page Views"
                variant="outlined"
                multiline
                rows={2}
                required
                value={formik.values.monthlyPageViewsText}
                onChange={formik.handleChange}
                error={formik.touched.monthlyPageViewsText && Boolean(formik.errors.monthlyPageViewsText)}
                helperText={formik.touched.monthlyPageViewsText && formik.errors.monthlyPageViewsText}
              />
            </Grid>
            }        
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Please provide your site or app list</FormLabel>
                <RadioGroup aria-label="siteList" name="siteListType"
                value={formik.values.siteListType} onChange={formik.handleChange}>
                  <FormControlLabel value="manually" control={<Radio />} label="I will enter my site list manually" />
                  <FormControlLabel value="upload" control={<Radio />} label="I want to  upload a file with my site/app data" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {formik.values.siteListType === 'manually' &&
            <Grid item xs={6}>
                <TextField
                id="siteListText"
                name="siteListText"
                label="Enter comma separated list of your sites or Apps"
                variant="outlined"
                multiline
                rows={2}
                required
                value={formik.values.siteListText}
                onChange={formik.handleChange}
                error={formik.touched.siteListText && Boolean(formik.errors.siteListText)}
                helperText={formik.touched.siteListText && formik.errors.siteListText}
              />
              <Typography variant="caption" display="block">Please note: the manual site list input has a limit of 4,000 characters. For larger site lists, please use the upload feature.</Typography>
            </Grid>
            }     
            {formik.values.siteListType === 'upload' &&
            <Grid item xs={6}>
            <FormControl fullWidth>

                <Typography variant="caption">Upload Site/App List *</Typography>

                <Box className={classes.flexRow}>
                  <Tooltip
                      title={<span className={classes.tooltip}>Please upload a file with your site data</span>}
                      placement="bottom" arrow
                  >
                <TextField
                  required
                  value={(formik.values.siteListFile &&  formik.values.siteListFile.name) || ''}
                  error={formik.touched.siteListFile && Boolean(formik.errors.siteListFile)}
                  helperText={formik.touched.siteListFile && formik.errors.siteListFile}
                  inputProps={
                    { readOnly: true, }
                  }/>                
                  </Tooltip>                
                <label htmlFor="siteListFile">
                  <Button variant="contained" component="span">
                  Browse
                  </Button>
                </label>

                <input
                  id="siteListFile"
                  type="file"
                  hidden
                  onChange={(e) => e.target.files
                    && formik.setFieldValue('siteListFile', e.target.files[0])}
                />

                </Box>
              </FormControl>

            </Grid>  
            }

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="verticalLabel">Primary Content Vertical (Select 3)</InputLabel>
                <Select
                  id="vertical"
                  name="vertical"
                  labelId="vertical"
                  label="Primary Content Vertical (Select 3) *"
                  displayEmpty
                  multiple
                  value={formik.values.verticals}
                  renderValue={(value: any) => options.verticals
                    .filter(v => value.includes(v.id))
                    .map(v => v.name)
                    .join(", ")}
                  onChange={(e) => formik.setFieldValue("verticals", e.target.value)}
                  error={formik.touched.verticals && Boolean(formik.errors.verticals)}
                >
                  {options.verticals && options.verticals.map((vertical) => {
                    return (
                    <MenuItem key={vertical.id} value={vertical.id} disabled={
                      formik.values.verticals
                        && formik.values.verticals.indexOf(vertical.id) === -1
                        && formik.values.verticals.length >= 3}>
                      <Checkbox checked={formik.values.verticals
                        && formik.values.verticals.indexOf(vertical.id) > -1} />
                      <ListItemText primary={vertical.name} />
                    </MenuItem>);
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Geography</Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="apacPercentageLabel">APAC</InputLabel>
                <Select
                  id="apacPercentage"
                  name="apacPercentage"
                  labelId="apacPercentageLabel"
                  label="APAC"
                  value={formik.values.apacPercentage || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.apacPercentage && Boolean(formik.errors.apacPercentage)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={20}>20%</MenuItem>
                  <MenuItem value={30}>30%</MenuItem>
                  <MenuItem value={40}>40%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={60}>60%</MenuItem>
                  <MenuItem value={70}>70%</MenuItem>
                  <MenuItem value={80}>80%</MenuItem>
                  <MenuItem value={90}>90%</MenuItem>
                  <MenuItem value={100}>100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="emeaPercentageLabel">EMEA</InputLabel>
                <Select
                  id="emeaPercentage"
                  name="emeaPercentage"
                  labelId="emeaPercentageLabel"
                  label="EMEA"
                  value={formik.values.emeaPercentage || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.emeaPercentage && Boolean(formik.errors.emeaPercentage)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={20}>20%</MenuItem>
                  <MenuItem value={30}>30%</MenuItem>
                  <MenuItem value={40}>40%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={60}>60%</MenuItem>
                  <MenuItem value={70}>70%</MenuItem>
                  <MenuItem value={80}>80%</MenuItem>
                  <MenuItem value={90}>90%</MenuItem>
                  <MenuItem value={100}>100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="latamPercentageLabel">LATAM</InputLabel>
                <Select
                  id="latamPercentage"
                  name="latamPercentage"
                  labelId="latamPercentageLabel"
                  label="LATAM"
                  value={formik.values.latamPercentage || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.latamPercentage && Boolean(formik.errors.latamPercentage)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={20}>20%</MenuItem>
                  <MenuItem value={30}>30%</MenuItem>
                  <MenuItem value={40}>40%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={60}>60%</MenuItem>
                  <MenuItem value={70}>70%</MenuItem>
                  <MenuItem value={80}>80%</MenuItem>
                  <MenuItem value={90}>90%</MenuItem>
                  <MenuItem value={100}>100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="usPercentageLabel">US</InputLabel>
                <Select
                  id="usPercentage"
                  name="usPercentage"
                  labelId="usPercentageLabel"
                  label="US"
                  value={formik.values.usPercentage || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.usPercentage && Boolean(formik.errors.usPercentage)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={20}>20%</MenuItem>
                  <MenuItem value={30}>30%</MenuItem>
                  <MenuItem value={40}>40%</MenuItem>
                  <MenuItem value={50}>50%</MenuItem>
                  <MenuItem value={60}>60%</MenuItem>
                  <MenuItem value={70}>70%</MenuItem>
                  <MenuItem value={80}>80%</MenuItem>
                  <MenuItem value={90}>90%</MenuItem>
                  <MenuItem value={100}>100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.terms}>
              <TermsAccordion/>
            </Grid>
            <Grid item xs={12}>
            <FormControlLabel control={
              <Checkbox
              id="acceptedTerms"
              name="acceptedTerms"
              checked={formik.values.acceptedTerms}
              onChange={formik.handleChange}/>
            }
            label={
              <span>By submitting this form you confirm that you agree with the <b>Yieldmo Publisher Terms for Google OB</b> above.</span>
            }
            />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary"
                size="large" type="submit" disabled={!formik.values.acceptedTerms}>Submit</Button>
            </Grid>
          </Grid>
        </form>
    </Container>
  );
}
