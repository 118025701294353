import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";

export default function FaqAccordion(props: any) {
    return (
        <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
            <Typography>FAQ</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
<h4>What is Google Onboard Online?</h4>

Google supports a program for AdMob and Google Ad Manager publishers that allows them to transact with demand sources through Open Bidding via a streamlined onboarding process built on an easy-to-use online application form and contract.

<h4>Publisher Benefits</h4>

Unlock new revenue streams by accessing Yieldmo as a demand partner through Google Ad Manager and AdMob. Simply fill out the application form, and once approved, start transacting with little lead time. Yieldmo’s marketplace provides preferred access to:
<ul>
  <li>Premium partners at scale</li>
  <li>High-impact <a href="https://formats.yieldmo.com/app-front/" target="_blank" rel="noreferrer">formats with proven results</a></li>
  <li>Private Marketplaces optimized with AEROS Attention Analytics</li>
  <li>Direct and Global deals</li>
</ul>

<h4>How to Sign Up</h4>

<ul>
  <li>Log into the Google Ad Manager or AdMob UI to locate a link to Yieldmo’s application form, or navigate directly to Yieldmo’s application <a href="https://openbidding.yieldmo.com" target="_blank" rel="noreferrer">form</a>.</li>
  <li>Submit a completed application.</li>
  <li>Publishers will be notified of Yieldmo’s decision on their application within 2-3 business days. If approved, publishers will receive ads.txt and/or app-ads.txt entries and may add Yieldmo to appropriate yield groups to begin monetizing with us.</li>
</ul>


<h4>Frequently Asked Questions</h4>

<strong>I’d like to make modifications to the IO. Where or how can I go about doing that?</strong>
<div>Due to the volume of applications, this program does not support modifications to the Yieldmo agreement.</div>
<br/>
<strong>What is the turnaround time after I have submitted my publisher form and e-signed the IO?</strong>
<div>Publishers can expect a response within 3 business days.</div>
<br/>
<strong>Will I be notified if I am approved or rejected?</strong>
<div>Yes, you will be notified of our decision. Additionally, the status of your application will always be reflected in either your Google Ad Manager or AdMob UI as one of the following:</div>
<ul>
  <li>“Pending” – your submission is still under review</li>
  <li>“Approved” – your submission was approved. Activity should begin soon after.</li>
  <li>“Rejected” – your submission was rejected</li>
</ul>
<br/>
<strong>Why was my application rejected?</strong>
<div>Due to the volume of submissions we receive, we are not able to provide details on decisions for an application that has been rejected.</div>
<br/>
<strong>What are the payment terms?</strong>
<div>Google is the party of record for payment. Please direct any inquiries to your Google account team.</div>
<br/>
<strong>Will I receive reporting access?</strong>
<div>Please refer to the reporting interface in your Google Ad Manager or AdMob account for all reporting.</div>
<br/>
<strong>What is Yieldmo’s Ad Policy?</strong>
<div>Click <a href="https://www.yieldmo.com/adpolicy/" target="_blank" rel="noreferrer">here</a> for a comprehensive listing of Yieldmo’s ad policy.</div>
<br/>
<strong>What ad formats and sizes do you support?</strong>
<div>Yieldmo supports banner, video, interstitial, and rewarded ad formats across web and app inventory as appropriate. A key differentiator that drives performance for buyers and yield for publishers is Yieldmo’s proprietary suite of patented and patent pending high-impact ad formats. All ad formats are fully responsive and serve into banner and interstitial inventory. Demos are available on the <a href="https://formats.yieldmo.com" target="_blank" rel="noreferrer">Yieldmo Ad Builder</a>.</div>
<br/>
<strong>Where can I implement floor CPMs for my inventory?</strong>
<div>Please use the Unified Pricing Rules from Google, as detailed <a href="https://support.google.com/admanager/answer/9298008?hl=en" target="_blank" rel="noreferrer">here</a>.</div>
<br/>
<strong>I am live with Yieldmo via Google’s Onboard Online program, but I have new inventory I would like to submit for consideration. How do I go about doing that?</strong>
<div>Please email <a href="mailto:obsupport@yieldmo.com">obsupport@yieldmo.com</a> the following <u>required items</u>:</div>
<ul>
  <li>Publisher Name</li>
  <li>Google Publisher or Network ID</li>
  <li>The site name(s) &amp; domain(s) (if applicable)</li>
  <li>The App Name(s), App Store Location(s) (both iOS and Android where applicable) and app-ads.txt location(s)</li>
  <li>Average monthly US bid requests</li>
</ul>
<br/>
Publishers will be advised of Yieldmo’s decision to whitelist new inventory within 3 business days. Ads.txt and app-ads.txt are required for all new inventory approved.
<br/>
<br/>
<a href="https://www.yieldmo.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>


            </Typography>
        </AccordionDetails>
        </Accordion>
    )
}
